<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />

      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold pa-0"
        >Onboarding Questions ({{ questionnaires.length }})</v-toolbar-title
      >

      <v-spacer></v-spacer>

      <v-row v-show="showSearch" align="center" style="max-width: 300px;">
        <v-text-field
          class="search"
          ref="searchField"
          flat
          hide-details
          solo
          rounded
          clearable
          background-color="grey lighten-3"
          label="Search body or description"
          v-model="filters.search"
          @input="search"
        ></v-text-field>
      </v-row>

      <v-skeleton-loader
        type="actions"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <template v-else>
        <v-btn icon class="ml-5" @click="showSearchField">
          <img src="@/assets/icons/search.svg" alt height="17.7px" />
        </v-btn>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              color="#383d3f"
              class="mr-1"
              icon
              @click="exportList"
              v-on="on"
            >
              <v-icon>{{ icons.export }}</v-icon>
            </v-btn>
          </template>
          <span>Export to Excel</span>
        </v-tooltip>
      </template>
    </v-app-bar>

    <div class="d-flex mt-6">
      <div>
        <v-btn
          color="primary"
          @click="
            $router.push({
              name: 'onboarding-questions.create'
            })
          "
        >
          <v-icon class="mr-1" small>{{ icons.add }}</v-icon
          >Add new onboarding question
        </v-btn>
      </div>
    </div>

    <div class="mt-6" id="onboarding-questions-list">
      <v-simple-table>
        <thead>
          <tr>
            <th width="10%" class="text-left pa-0">Order</th>
            <th width="30%" class="text-left pa-0">Body</th>
            <th width="30%" class="text-left pa-0">Description</th>
            <th width="20%" class="text-left pa-0">Choices</th>
            <th width="10%" class="text-left pa-0">...</th>
          </tr>
        </thead>
        <draggable v-model="questionnaires" tag="tbody">
          <tr
            v-for="question in questionnaires"
            :key="question.id"
            class="clickable"
          >
            <td scope="row" width="10%" class="text-left pa-0">
              <v-icon class="mr-2">{{ icons.order }}</v-icon>
              {{ question.order }}
            </td>
            <td width="30%">{{ question.body }}</td>
            <td width="30%">{{ question.description }}</td>
            <td width="20%">
              <ul>
                <li v-for="choice in question.choices" :key="choice.id">
                  {{ choice.name }}
                </li>
              </ul>
            </td>
            <td width="10%">
              <v-btn
                elevation="2"
                color="primary"
                @click="
                  $router.push({
                    name: 'onboarding-questions.edit',
                    params: { id: question.id }
                  })
                "
                >Edit</v-btn
              >
            </td>
          </tr>
          <template v-if="loading">
            <tr v-for="row in 10" :key="`${row}-skeleton`">
              <td class="pl-0" v-for="col in 5" :key="col">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
            </tr>
          </template>
        </draggable>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import debounce from 'lodash/debounce'
import ControlsMixin from '@/utils/mixins/Controls'
import { mapActions, mapMutations, mapState } from 'vuex'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import { mdiPlus, mdiFileExcel, mdiReorderHorizontal } from '@mdi/js'

export default {
  name: 'OnboardingQuestionsPage',

  mixins: [ControlsMixin],

  components: {
    AppBarNavIcon,
    draggable
  },

  data() {
    return {
      showSearch: false,
      loading: false,
      filters: {
        search: ''
      },
      icons: {
        add: mdiPlus,
        export: mdiFileExcel,
        order: mdiReorderHorizontal
      }
    }
  },

  mounted() {
    this.clearProjectTypes()
    this.getProjectTypes()
  },

  updated() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.$refs.searchField.$refs.input.focus()
      })
    })
  },

  computed: {
    ...mapState({
      projectTypes: state => state.projectTypes.list
    }),

    questionnaires: {
      get() {
        return this.$store.state.projectTypes.questionnaires
      },
      set(questions) {
        this.$store.dispatch('projectTypes/reorderQuestionnaires', questions)
      }
    }
  },

  methods: {
    ...mapActions({
      fetchProjectTypes: 'projectTypes/fetchProjectTypes',
      exportToExcel: 'projectTypes/exportOnboardingQuestion'
    }),

    ...mapMutations({
      clearProjectTypes: 'projectTypes/clearProjectTypes'
    }),

    async getProjectTypes() {
      this.loading = true
      await this.fetchProjectTypes(this.filters.search)
      this.loading = false
    },

    search: debounce(function() {
      this.clearProjectTypes()
      this.getProjectTypes()
    }, 600),

    async showSearchField() {
      this.showSearch = !this.showSearch
      await this.$nextTick()
      this.$refs.searchField.$refs.input.focus()
    },

    async exportList() {
      this.loading = true
      await this.exportToExcel()
        .then(response => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel'
            })
          )

          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'onboarding-questions.xlsx')
          document.body.appendChild(link)
          link.click()

          this.$store.dispatch('showSnackbar', {
            message: 'File successfully downloaded',
            color: 'success'
          })
        })
        .catch(() => {
          this.$store.dispatch('showSnackbar', {
            message: 'Ops! Something went wrong',
            color: 'red'
          })
        })
      this.loading = false
    }
  }
}
</script>
