var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{staticClass:"main-appbar",attrs:{"flat":"","color":"transparent"}},[_c('app-bar-nav-icon'),(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"chip","transition":"fade-transition"}}):_c('v-toolbar-title',{staticClass:"headline font-weight-bold pa-0"},[_vm._v("Onboarding Questions ("+_vm._s(_vm.questionnaires.length)+")")]),_c('v-spacer'),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.showSearch),expression:"showSearch"}],staticStyle:{"max-width":"300px"},attrs:{"align":"center"}},[_c('v-text-field',{ref:"searchField",staticClass:"search",attrs:{"flat":"","hide-details":"","solo":"","rounded":"","clearable":"","background-color":"grey lighten-3","label":"Search body or description"},on:{"input":_vm.search},model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}})],1),(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"actions","transition":"fade-transition"}}):[_c('v-btn',{staticClass:"ml-5",attrs:{"icon":""},on:{"click":_vm.showSearchField}},[_c('img',{attrs:{"src":require("@/assets/icons/search.svg"),"alt":"","height":"17.7px"}})]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"color":"#383d3f","icon":""},on:{"click":_vm.exportList}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.export))])],1)]}}])},[_c('span',[_vm._v("Export to Excel")])])]],2),_c('div',{staticClass:"d-flex mt-6"},[_c('div',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.push({
            name: 'onboarding-questions.create'
          })}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(_vm._s(_vm.icons.add))]),_vm._v("Add new onboarding question ")],1)],1)]),_c('div',{staticClass:"mt-6",attrs:{"id":"onboarding-questions-list"}},[_c('v-simple-table',[_c('thead',[_c('tr',[_c('th',{staticClass:"text-left pa-0",attrs:{"width":"10%"}},[_vm._v("Order")]),_c('th',{staticClass:"text-left pa-0",attrs:{"width":"30%"}},[_vm._v("Body")]),_c('th',{staticClass:"text-left pa-0",attrs:{"width":"30%"}},[_vm._v("Description")]),_c('th',{staticClass:"text-left pa-0",attrs:{"width":"20%"}},[_vm._v("Choices")]),_c('th',{staticClass:"text-left pa-0",attrs:{"width":"10%"}},[_vm._v("...")])])]),_c('draggable',{attrs:{"tag":"tbody"},model:{value:(_vm.questionnaires),callback:function ($$v) {_vm.questionnaires=$$v},expression:"questionnaires"}},[_vm._l((_vm.questionnaires),function(question){return _c('tr',{key:question.id,staticClass:"clickable"},[_c('td',{staticClass:"text-left pa-0",attrs:{"scope":"row","width":"10%"}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.icons.order))]),_vm._v(" "+_vm._s(question.order)+" ")],1),_c('td',{attrs:{"width":"30%"}},[_vm._v(_vm._s(question.body))]),_c('td',{attrs:{"width":"30%"}},[_vm._v(_vm._s(question.description))]),_c('td',{attrs:{"width":"20%"}},[_c('ul',_vm._l((question.choices),function(choice){return _c('li',{key:choice.id},[_vm._v(" "+_vm._s(choice.name)+" ")])}),0)]),_c('td',{attrs:{"width":"10%"}},[_c('v-btn',{attrs:{"elevation":"2","color":"primary"},on:{"click":function($event){return _vm.$router.push({
                  name: 'onboarding-questions.edit',
                  params: { id: question.id }
                })}}},[_vm._v("Edit")])],1)])}),(_vm.loading)?_vm._l((10),function(row){return _c('tr',{key:(row + "-skeleton")},_vm._l((5),function(col){return _c('td',{key:col,staticClass:"pl-0"},[_c('v-skeleton-loader',{attrs:{"type":"list-item","transition":"fade-transition"}})],1)}),0)}):_vm._e()],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }